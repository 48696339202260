<template>
  <div>
    <div
      v-if="isAdd || isCustomize || isDelete"
      class="modal-backdrop show"
      @click="closeAll()"
    ></div>

    <div v-if="isAdd" class="modal" style="display: block">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="v-card__title pb-1 pr-8"><i aria-hidden="true" class="v-icon notranslate mr-3 mdi mdi-square-edit-outline theme--light" style="font-size: 40px;"></i>Cadastrar Destaque</h4>
            <button  @click="closeAll()" type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default" style="position: absolute; right: 5px; top: 5px;"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close-circle-outline theme--light"></i></span></button>
          </div>
          
          <div
                  class="modal-info"
                  style="
                    color: black; 
                    margin: 5px;"
                >
                <b>Descrição</b> = Descrição da rubrica no cálculo.<br>
                <b>Fórmula</b> = Preencha apenas se entender o uso.<br>
                <b>Referência</b> = Campo da FOPAG expresso em dias, horas ou percentual.<br>
                <b>Valor</b> = Campo da FOPAG expresso R$.<br>
                <b>+ Base</b> = Acima “Total de Vencimentos” no cálculo, encargos incidem sobre a rubrica.<br>
                <b>+ Total</b> = Abaixo “Total de Vencimentos” no cálculo, encargos <b>não</b> incidem sobre a rubrica.<br>
                <b>Consideração</b> = Comportamento da rubrica no cálculo.<br>
                <b>Benefício</b> = Se entra no cálculo da Lei do Bem, Lei de Informática ou ambos.
                </div>
          

          <div class="modal-body">
            <div>
              <div>
                <div style="float: left; width: calc(60%)">
                  <kore-input
                    v-model="tmp.nome"
                    rules="required|min:5"
                    placeholder="Descrição da Rubrica no Teros"
                    ><span style="color: #0f131e">Descrição</span></kore-input
                  >
                </div>

                <div style="float: right; width: calc(30%)">
                  <kore-input
                    v-model="tmp.formula"
                    rules="required|min:5"
                    placeholder="Preencha apenas se entender o uso"
                    ><span style="color: #0f131e">Fórmula</span></kore-input
                  >
                </div>

                
              </div>

              

                <div style="clear: both"></div>
              

              <div>

                
                <div
                  class="control-label bold small text-uppercase"
                  style="color: black"
                >
                  Aplicações Referência
                </div>

                <div style="margin-top: 5px">
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    + Base
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    + Total
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    Horas
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    Dias
                  </div>
                </div>
                <div style="margin-top: 5px">
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.FBase" type="checkbox"></kcheck>
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.FTotal" type="checkbox"></kcheck>
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.FHoras" type="checkbox"></kcheck>
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.FDias" type="checkbox"></kcheck>
                  </div>
                </div>

                <div style="clear: both"></div>
              </div>

              <div>
                <div
                  class="control-label bold small text-uppercase"
                  style="color: black; margin-top: 15px"
                >
                  Aplicações Valor
                </div>

                <div style="margin-top: 5px">
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    + Base
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    + Total
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    Horas
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    Dias
                  </div>
                </div>
                <div style="margin-top: 5px">
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.TBase" type="checkbox"></kcheck>
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.TTotal" type="checkbox"></kcheck>
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.THoras" type="checkbox"></kcheck>
                  </div>
                  <div
                    style="
                      float: left;
                      width: 25%;
                      text-align: center;
                      padding: 2px;
                    "
                  >
                    <kcheck v-model="tmp.TDias" type="checkbox"></kcheck>
                  </div>
                </div>

                <div style="clear: both"></div>
              </div>

              <div>
                <div style="float: left; width: calc(80%); margin-top: 15px">
                  <kore-input
                    v-model="tmp.Subtract"
                    type="radio"
                    :options="[
                      { id: 1, name: 'Subtrair' },
                      { id: -1, name: 'Somar' },
                    ]"
                    :info="{ key: 'id', name: 'name' }"
                    ><span style="color: #0f131e"
                      >Modo Consideração</span
                    ></kore-input
                  >
                </div>

                <div style="clear: both"></div>
              </div>

              <div>
                <div style="float: left; width: calc(80%); margin-top: 15px">
                  <kore-input
                    v-model="tmp.trabalho"
                    type="radio"
                    :options="opts.trabalho"
                    :info="{ key: 'id', name: 'nome' }"
                    ><span style="color: #0f131e"
                      >Benefício</span
                    ></kore-input
                  >
                </div>

                <div style="clear: both"></div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="float-left" style="text-align: center">
              <button
                @click="doCancel()"
                class="btn btn-primary"
                type="submit"
              >
                <i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-chevron-left theme--dark"></i>
                <span>Cancelar</span>
              </button>
            </div>

            <div class="float-right" style="text-align: center">
              <button @click="doSave()" class="btn btn-primary" type="submit">
                <span v-if="tmp.id">Salvar</span>
                <span v-else>Cadastrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <v-dialog v-model="empresasModal" scrollable persistent max-width="600">
      <v-card>
        <v-card-title class="pb-1 pr-8">
          <v-icon x-large class="mr-3">mdi-card-text-outline</v-icon>
          <h3 style="width: 90%; word-break: normal;">Empresas que usam a rubrica<br />{{ rubricaNome }}</h3>
          <v-btn @click="empresasModal = false" style="position: absolute; right: 5px; top: 5px" icon>
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 form_container">
          <kore-table
            v-if="empresas.length > 0"
            :cols="empresaCols"
            :opts="opts"
            :rows="empresas"
            :has-filter="false"
            height="528px"
          ></kore-table>
          <div v-else>
            Nenhuma empresa utiliza esta rubrica.
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="px-5" dark depressed @click="empresasModal = false">
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <kore-table
      :cols="cols"
      :opts="opts"
      :rows="fRows"
      :actionBarButtons="actionBarButtons"
      :hasFilter="true"
      @contextmenu="actContext"
      @rowstoprint="rowstoprint"
    >
    </kore-table>
  </div>
</template>

<style scoped lang="scss">
.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 3px;
}

.overflow {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
}

.kmodal {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 60;
  transform: translate(-50%, -50%);
  min-width: 300px;
  min-height: 300px;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.actions {
  div {
    float: left;
    margin-right: 40px;
    cursor: pointer;
    font-weight: bold;
    color: #000000;

    img {
      margin: 5px;
    }
  }

  &:after {
    clear: both;
    content: " ";
  }
}

.filter {
  select {
    height: 33px;
    width: 100%;
  }
}
</style>


<script>
import _ from "lodash";

export default {
  components: {
    "kore-input": () => import("../../components/kInput.vue"),
    kcheck: () => import("../../components/kcheck.vue"),
    "kore-table": () => import("../../components/table-v.vue"),
    //'kore-row': ()=>import('../../components/table.row.vue')
  },
  created: function () {
    this.doLoad();
  },
  methods: {
    actContext: function (v, e) {
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu: this.options,
      });
    },
    doCancel: function () {
      this.isAdd = false;
      this.isDelete = false;
      this.tmp = {};
    },
    doSave: function () {
      if (this.isSend === false) {
        this.isSend = true;
        this.isErrorMessage = "";
        var url = "";
        if (this.tmp.id) {
          url = "/v1/sistema/destaques/" + this.tmp.id;
        } else {
          url = "/v1/sistema/destaques";
        }

        this.korePost(url, this.tmp, (a) => {
          this.isSend = false;
          if (a.error) {
            this.isErrorMessage = a.error;
          } else {
            this.doLoad();
            this.doCancel();
          }
        });
      }
    },
    doAdd: function () {
      this.isAdd = true;
    },
    doEdit: function (rows) {
      this.tmp = _.find(this.rows, { id: rows.id });
      this.isAdd = true;
    },
    doDelete: function (rows, c = false) {
      if (c === false) {
        this.deletes = rows;
        this.isDelete = true;
      } else {
        this.isSend = true;

        var url = "/v1/sistema/destaques";
        this.koreDelete(url, { delete: rows }, (a) => {
          this.isSend = false;
          if (a.error) {
            this.isErrorMessage = a.error;
          } else {
            this.doLoad();
            this.doCancel();
          }
        });
      }
    },
    doCustomize: function () {
      this.isCustomize = true;
    },
    closeAll: function () {
      this.isAdd = false;
      this.isCustomize = false;
    },
    doLoad: function () {
      this.koreGet(this.url, (a) => {
        this.loaded = true;
        if (!a.error) {
          this.rows = a;
        }
      });
    },
    getEmpresas: function (rubricaId) {
      return this.apiResource(`v1/sistema/clientesTemplate/${rubricaId}`).get().then((response) => {
        this.empresas = response;
        return response;
      });
    },
    rowstoprint: function(resolve) {
      const oldStatusArray = this.opts.status;
      this.opts.status = [
          {
            id: -1,
            name: 'Não',
          },
          {
            id: 1,
            name: 'Sim',
          },
        ];
      resolve(this.rows);
      setTimeout(() => {
        this.opts.status = oldStatusArray;
      }, 1000);
    },
  },
  computed: {
    cLabels: function () {
      return _.filter(this.labels, function (l) {
        return l.type != 0 && l.type != -1;
      });
    },
    fRows: function () {
      var filters = {};

      if (this.filterCliente != 0 || this.filterServico != 0) {
        if (this.filterCliente != 0) {
          filters["empresaId"] = this.filterCliente;
        }

        return _.filter(this.rows, filters);
      } else {
        return this.rows;
      }
    },
    empresaCols: function () {
      return [
        {
          key: "empresa",
          name: "Empresa",
        },
        {
          key: "rubId",
          name: "Código FOPAG",
        },
        {
          key: "codigoFopag",
          name: "Descrição FOPAG",
        },
      ];
    },
  },
  data: function () {
    return {
      actionBarButtons: [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.doAdd();
          },
        },
      ],
      deletes: [],
      isSend: false,
      isErrorMessage: "",
      empresasModal: false,
      empresas: [],
      rubricaNome: '',
      filterCliente: 0,
      filterServico: 0,
      isDelete: false,
      isAdd: false,
      isCustomize: false,
      tmp: {},
      url: "/v1/sistema/destaques",
      rows: [],
      options: [
        {
          name: "Ver empresas",
          limit: 1,
          icon: "",
          cb: (e) => {
            this.rubricaNome = e.nome;
            this.getEmpresas(e.id).then(() => {
              this.empresasModal = true;
            });
          },
        },
        {
          name: "Editar",
          limit: 1,
          icon: "",
          cb: (e) => {
            this.doEdit(e);
          },
        },
      ],
      opts: {
        status: [
          {
            id: -1,
            name: '<i class="v-icon notranslate mdi mdi-checkbox-blank-circle-outline theme--dark" style="color: #d0d0d0"></i>',
          },
          {
            id: 1,
            name: '<i class="v-icon notranslate mdi mdi-checkbox-blank-circle theme--dark" style="color: #367be2"></i>',
          },
        ],
        trabalho: [
          {
            id: 1,
            nome: "Lei do Bem",
          },
          {
            id: 2,
            nome: "Lei de Informática",
          },
          {
            id: 3,
            nome: "Ambos",
          },
        ],
      },
      cols: [
        {
          key: "id",
          name: "ID",
          size: 100,
          align: 0,
          show: true,
          editable: false,
          type: 0,
        },
        {
          key: "nome",
          name: "Nome",
          size: 0,
          align: -1,
          show: true,
          editable: true,
          type: 1,
        },
        {
          key: "quantidade_empresas",
          name: "Clientes",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 1,
        },
        {
          key: "FBase",
          name: "Referência + Base",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "FTotal",
          name: "Referência + Total",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "FHoras",
          name: "Referência como Horas",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "FDias",
          name: "Referência como Dias",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "TBase",
          name: "Valor + Base",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "TTotal",
          name: "Valor + Total",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "THoras",
          name: "Valor como Horas",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "TDias",
          name: "Valor como Dias",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "Subtract",
          name: "Subtrair",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 4,
          rel: { to: "status", key: "id", name: "name" },
        },
        {
          key: "trabalho",
          name: "Benefício",
          show: true,
          editable: true,
          rel: { to: "trabalho", key: "id", name: "nome" },
        },
        {
          key: "formula",
          name: "Fórmula",
          size: 150,
          align: 0,
          show: true,
          editable: true,
          type: 1,
        },
      ],
    };
  },
};
</script>